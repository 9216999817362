import React from "react";
import { useFormikContext } from "formik";
import ColorPickerControl from "../../components/Form/ColorPickerControl";

const ColorBox = ({ color, disabled }) => {
  return (
    <div
      role="button"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
      style={{
        width: "35px",
        height: "35px",
        backgroundColor: color,
        borderRadius: "3px",
      }}
    />
  );
};

const WebchatColorPicker = ({ disabled }) => {
  const formik = useFormikContext();

  const onChange = (newColor) => {
    formik.setFieldValue("theme_end_color", newColor);
  };

  const style = {};
  if (disabled) {
    style.opacity = ".5";
    style.pointerEvents = "none";
  }

  return (
    <div className="d-flex gap-3 dropup" style={style}>
      <ColorBox color={formik.values.theme_start_color} />
      <input
        className="form-control"
        value={formik.values.theme_start_color}
        readOnly
        style={{ width: "100px" }}
      />
      <ul className="dropdown-menu" style={{ border: "none" }}>
        <ColorPickerControl
          id="theme_start_color"
          name="theme_start_color"
          onChange={onChange}
        />
      </ul>
    </div>
  );
};

export default WebchatColorPicker;
