import React from "react";

const NotificationInIframeWarning = ({ hidden }) => {
  if (hidden) return null;
  return (
    <div className="alert alert-warning">
      <div className="mb-2">
        Please open Ikeono in a separate tab to enable notifications.
      </div>
      <a
        className="btn btn-primary btn-sm"
        href="https://app.ikeono.com"
        target="_blank"
        rel="noreferrer"
      >
        Open Ikeono
      </a>
    </div>
  );
};

export default NotificationInIframeWarning;
