/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

export const BaseSelectorItem = ({
  id,
  name,
  isActive,
  onSelect,
  children,
}) => {
  const onClick = () => {
    onSelect(id);
  };
  return (
    <div
      className={`card cursor-pointer w-100 ${
        isActive ? "border-primary" : ""
      }`}
      onClick={onClick}
    >
      <div className="card-body text-center d-flex flex-column align-items-center">
        {children}
        <div className="fs-14 mt-auto">{name}</div>
      </div>
    </div>
  );
};

const SelectorItem = ({
  activeImage,
  inactiveImage,
  isActive,
  imageProps,
  ...props
}) => {
  return (
    <BaseSelectorItem isActive={isActive} {...props}>
      {isActive ? activeImage : inactiveImage}
    </BaseSelectorItem>
  );
};

export default SelectorItem;
