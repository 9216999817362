import React from "react";
import { subscribeUser } from "../services/notification";

const NotificationSubscribe = ({ onComplete, hidden, hideDetail = false }) => {
  if (hidden) return null;
  return (
    <div className="mb-2">
      <p hidden={hideDetail}>
        Enable push notificaions to receive important alerts. Such as new
        message notifications, feedback scores and more.
      </p>
      <button
        type="button"
        className="btn btn-primary px-3 mt-1"
        onClick={async () => {
          try {
            await subscribeUser();
          } catch (error) {
            console.log(error);
          }
          onComplete && onComplete();
        }}
      >
        <i className="bi-bell pe-2" />
        Enable push notifications
      </button>
    </div>
  );
};

export default NotificationSubscribe;
