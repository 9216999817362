import React from "react";

const WebchatCopyCodeButton = ({ widgetCode }) => {
  const onClick = (e) => {
    navigator.clipboard.writeText(widgetCode);
    alert("Code copied to clipboard!");
  };

  return (
    <button
      className="btn btn-sm btn-outline-secondary"
      type="button"
      onClick={onClick}
    >
      <i className="bi-copy" style={{ marginRight: "5px" }} />
      Copy Code
    </button>
  );
};

export default WebchatCopyCodeButton;
