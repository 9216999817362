import React from "react";
import { WebchatUpgradeModalId } from "./WebchatUpgradeModal";
import { useState } from "../../lib/overmind";

const index = () => {
  const state = useState().account;
  const [hidden, setHidden] = React.useState(true);

  React.useEffect(() => {
    console.log("tmp: tier,", state.webchatTier);
    if (state.webchatTier === "premium" || !state.webchatTier) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [state.webchatTier]);

  if (hidden) return null;

  return (
    <button
      type="button"
      className="btn btn-primary rounded-pill px-3"
      data-bs-toggle="modal"
      data-bs-target={`#${WebchatUpgradeModalId}`}
      style={{
        border: "none",
        color: "white",
        background:
          "linear-gradient(90deg, rgba(137,0,242,1) 0%, rgba(46,106,217,1) 100%)",
        fontWeight: 600,
        fontSize: "15px",
      }}
    >
      <i className="bi-stars me-1" />
      Upgrade to access more features
      <i className="bi-stars ms-1" />
    </button>
  );
};

export default index;
