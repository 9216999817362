import React from "react";
import { Tooltip } from "./Tooltip";

export const FeatureTierBadge = ({ premium, hidden, className }) => {
  if (hidden) return null;
  const variant = premium ? "primary" : "success";
  const title = premium ? "premium feature" : "standard feature";
  const tier = premium ? "Premium" : "Standard";

  return (
    <Tooltip title={`This feature is available at the ${tier} Tier.`}>
      <span
        style={{ fontSize: "11px" }}
        className={`badge border border-${variant} text-${variant} text-capitalize ${className}`}
      >
        <i className="bi-stars me-1" />
        {title}
      </span>
    </Tooltip>
  );
};
