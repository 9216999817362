import React from "react";
import { useFormikContext } from "formik";
import SelectorItem from "./SelectorItem";

const SelectorControl = ({ name, values, disabled }) => {
  const [selectedValue, setSelectedValue] = React.useState();
  const formik = useFormikContext();

  const onItemSelect = (id) => {
    setSelectedValue(id);
    formik.setFieldValue(name, id);
  };

  React.useEffect(() => {
    setSelectedValue(formik.values[name]);
  }, [values]);

  const style = disabled ? { opacity: 0.5, pointerEvents: "none" } : null;

  return (
    <div className="d-flex gap-4 position-relative" style={style}>
      {values?.map((value) => {
        return (
          <SelectorItem
            {...value}
            isActive={selectedValue === value.id}
            onSelect={onItemSelect}
          />
        );
      })}
    </div>
  );
};

export default SelectorControl;
