import React from "react";
import { useFormikContext } from "formik";
import SelectorItem, {
  BaseSelectorItem,
} from "../../components/Form/SelectorControl/SelectorItem";
import { usePost } from "../../lib/api";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";

const WebchatGreeterSelector = ({ disabled }) => {
  const formik = useFormikContext();
  const [inputRef] = React.useState(React.createRef());
  const [post, isLoading, error, resp] = usePost();

  const convertImage = async (file) => {
    const payload = new FormData();
    payload.set("greeter_image_file", file);
    if (!file) return;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    post(`/account/{account_id}/website-widget/greeter-image`, payload, config);
  };

  React.useEffect(() => {
    if (!resp) return;
    const { image_url } = resp.data;
    formik.setFieldValue("greeter_image_url", image_url);
  }, [resp]);

  const hasImage = formik.values.greeter_image_url;
  const style = disabled ? { opacity: 0.5, pointerEvents: "none" } : null;

  return (
    <div style={style}>
      <div className="d-flex gap-4 position-relative">
        <input
          ref={inputRef}
          id="greeter_file"
          name="greeter_file"
          type="file"
          className="d-none"
          accept="image/*"
          onChange={(event) => {
            convertImage(event.currentTarget.files[0]);
          }}
        />
        <SelectorItem
          id="greeter_image_url"
          name="Default Greeter URL"
          activeImage={
            <img className="w-100" src="/widget/greeter-selected.svg" />
          }
          inactiveImage={
            <img className="w-100" src="/widget/greeter-unselected.svg" />
          }
          isActive={!hasImage}
          onSelect={() => {
            formik.setFieldValue("greeter_image_url", null);
          }}
        />
        <BaseSelectorItem
          id="greeter_image_url"
          name="Custom Greeter URL"
          activeImage="/widget/greeter-selected.svg"
          inactiveImage="/widget/greeter-unselected.svg"
          isActive={hasImage}
          onSelect={() => inputRef.current?.click()}
        >
          <img
            src={formik.values.greeter_image_url}
            height={40}
            width={40}
            hidden={!formik.values.greeter_image_url}
          />
          <img
            className="w-100"
            src="/widget/custom-greeter-unselected.svg"
            hidden={formik.values.greeter_image_url}
          />
        </BaseSelectorItem>
        <div
          className="position-absolute top-0 w-100 h-100 justify-content-center align-items-center"
          style={{ display: isLoading ? "flex" : "none" }}
        >
          <div
            className="w-100 h-100"
            style={{
              backgroundColor: "gray",
              opacity: ".1",
            }}
          />
          <div
            className="w-100 h-100 position-absolute "
            style={{
              top: "calc(50% - 10px)",
            }}
          >
            <Loader variant="lg" />
          </div>
        </div>
      </div>
      <APIResponseError response={error} />
    </div>
  );
};

export default WebchatGreeterSelector;
