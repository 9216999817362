import React from "react";
import Modal, { ModalVariant } from "../../components/Modal";
import { useActions } from "../../lib/overmind";

export const WebchatShowCodeButtonModalId = "webchat-show-code-button-modal";

const WebchatShowCodeModal = () => {
  const actions = useActions().account;

  return (
    <Modal
      id={WebchatShowCodeButtonModalId}
      variant={ModalVariant.lg}
      title="Webchat Code"
    >
      <div className="d-flex justify-content-center">
        <div
          className="my-3 d-flex align-items-center flex-column"
          style={{ minWidth: "500px" }}
        >
          <div
            className="mb-3 text-secondary text-center"
            style={{ maxWidth: "35ch" }}
          >
            You can click on the below code to copy it to your clipboard.
          </div>
          <textarea
            className="form-control px-3"
            readOnly
            style={{ width: "100%", height: "200px", fontSize: "15px" }}
            value={`<script>${actions.getWidgetCode()}</script>`}
            onFocus={(e) => e.target.select()}
            onSelect={(e) => e.target.select()}
          />
        </div>
      </div>
    </Modal>
  );
};

export default WebchatShowCodeModal;
