import React from "react";

const NotificationDoneButton = () => {
  return (
    <div className="text-end mt-4">
      <button
        className="btn btn-outline-secondary"
        type="button"
        data-bs-dismiss="modal"
      >
        Done
      </button>
    </div>
  );
};

export default NotificationDoneButton;
