import React, { useEffect } from "react";
import SimpleForm from "../../components/Form/SimpleForm";
import { SettingsFactory } from "../../components/Settings/SettingsFactory";
import { put, useGet } from "../../lib/api";
import { prependHttps } from "../../lib/url";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";
import { fields } from "./fields";
import { settingsV2Adaptor } from "../../components/Settings";
import { useActions } from "../../lib/overmind";

const WebchatForm = () => {
  const [initialValues, setInitialValues] = React.useState();
  const [get, isLoading, error, resp] = useGet();
  const [computedFields, setComputedFields] = React.useState();
  const actions = useActions().account;

  const apiSubmitHandler = async (data) => {
    data.website = prependHttps(data.website);
    data.review_link = prependHttps(data.review_link);
    data.theme_end_color = data.theme_start_color;

    const selectedLocations = Object.keys(data).filter((key) => {
      return key.includes("shop__") && data[key];
    });
    if (!selectedLocations?.length) {
      const locationError = new Error("Please select at least one location");
      locationError.source = "ik";
      throw locationError;
    }

    await put(`/account/{account_id}/website-widget`, data);
  };

  useEffect(() => {
    get("/account/{account_id}/website-widget");
  }, []);

  useEffect(() => {
    if (!resp || !resp.data) return;

    const [newFields, iValues] = settingsV2Adaptor(fields, resp.data);
    for (const location of resp.data.locations) {
      iValues[`shop__${location.id}`] = location.selected;
    }

    setComputedFields(newFields);
    setInitialValues(iValues);
    actions.setWebchatTier(resp.data._meta.account_tier);
  }, [resp]);

  if (isLoading) return <Loader />;
  if (error) return <APIResponseError response={error} />;
  if (!resp || !computedFields || !initialValues) return null;

  return (
    <SimpleForm
      initialValues={initialValues}
      apiSubmitHandler={apiSubmitHandler}
    >
      <SettingsFactory
        fields={fields}
        data={{
          locations: resp.data.locations,
        }}
      />
    </SimpleForm>
  );
};

export default WebchatForm;
