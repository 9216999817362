/* eslint-disable no-shadow */
import React, { useEffect } from "react";
import LocationDetail from "./LocationDetail";
import { useGet } from "../lib/api";
import Loader from "../components/Loader";
import APIResponseError from "../components/APIResponseError";

const LocationManagement = () => {
  const [get, isLoading, error, resp] = useGet();

  useEffect(() => {
    get("/user/shops?shop_id={exid}");
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <APIResponseError response={error} />;
  if (!resp) return null;
  return <LocationDetail location={resp.data.data[0]} />;
};

export default LocationManagement;
