/* eslint-disable camelcase */
import { json } from "overmind";
import { mixpanel } from "../../../services/mixpanel";
import { prependStringToKeys } from "../../arrayUtil";

export const onInitializeOvermind = ({ actions, state }, instance) => {
  instance.reaction(
    ({ account }) => account.data,
    () => {
      const userId = state.account.data?.user?.id;
      if (!userId) {
        console.log(
          "Warning: Unable to initialize Mixpanel. Unable to find a user id",
        );
        state.tracking.error =
          "Warning: Unable to initialize Mixpanel. Unable to find a user id";
        return;
      }
      mixpanel.init();
      mixpanel.identify(userId);
      state.tracking.mixpanelInitialized = true;
      state.tracking.error = null;

      const {
        account,
        shop,
        user,
        is_trial_expired,
        days_left_on_trial,
      } = state.account.data;
      mixpanel.register({
        "shop name": shop.name,
        "account id": account.id,
        "user id": user.id,
        "user email": user.email,
        "user is readonly": user.is_readonly,
        "account trial expired": is_trial_expired,
        "days left on trial": days_left_on_trial,
      });

      mixpanel.setPeople({
        "user id": user.id,
        "user email": user.email,
        "first name": user.first_name,
        "last name": user.last_name,
      });

      mixpanel.track(
        "account loaded",
        json({
          ...prependStringToKeys(shop, "shop "),
          ...prependStringToKeys(account, "account "),
          ...prependStringToKeys(user, "user "),
        }),
      );
    },
  );
};
