import React from "react";
import WebchatDownloadButton from "./WebchatDownloadButton";
import WebchatShowCodeButton from "./WebchatShowCodeButton";
import WebchatCopyCodeButton from "./WebchatCopyCodeButton";
import { useActions } from "../../lib/overmind";

const WebchatPreview = () => {
  const actions = useActions().account;

  const codeWithScriptTag = `<script>${actions.getWidgetCode()}</script>`;

  return (
    <div className="d-flex gap-3">
      <WebchatCopyCodeButton widgetCode={codeWithScriptTag} />
      <WebchatShowCodeButton widgetCode={codeWithScriptTag} />
      <WebchatDownloadButton widgetCode={actions.getWidgetCode()} />
    </div>
  );
};

export default WebchatPreview;


  