import React from "react";

const SelectorButton = ({ name, id, isActive, onSelect, disabled }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn w-100 ${isActive ? "btn-dark" : "btn-outline-dark"}`}
      onClick={() => onSelect(id)}
    >
      {name}
    </button>
  );
};

export default SelectorButton;
