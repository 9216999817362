import React from "react";
import WebchatForm from "./WebchatForm";
import WebchatPreview from "./WebchatPreview";
import WebchatUpgrade from "./WebchatUpgrade";

const Webchat = () => {
  return (
    <>
      <div
        className="d-flex position-sticky top-0 bg-white justify-content-center align-items-center pb-2 border-bottom mb-3 gap-3"
        style={{ zIndex: 500 }}
      >
        <span className="h5 m-0 fw-semibold">Webchat Widget</span>
        <div className="ms-2">
          <WebchatUpgrade />
        </div>
        <div className="ms-auto">
          <WebchatPreview />
        </div>
      </div>

      <div className="d-flex mb-5 gap-4">
        <WebchatForm />
      </div>
    </>
  );
};

export default Webchat;
