import React from "react";
import Modal, { ModalVariant } from "../../components/Modal";
import WebchatPremiumFeatures from "./WebchatPremiumFeatures";
import WebchatStandardFeatures from "./WebchatStandardFeatures";

export const WebchatUpgradeModalId = "webchat-upgrade-modal";

const WebchatUpgradeModal = () => {
  return (
    <Modal
      id={WebchatUpgradeModalId}
      variant={ModalVariant.xl}
      title="Access more Webchat features"
    >
      <div className="d-flex gap-5 mx-5 my-3">
        <WebchatStandardFeatures />
        <div className="border-end" />
        <WebchatPremiumFeatures />
      </div>
    </Modal>
  );
};

export default WebchatUpgradeModal;
