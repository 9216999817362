import React from "react";

import WebchatColorPicker from "./WebchatColorPicker";
import WebchatGreeterSelector from "./WebchatGreeterSelector";
import WebchatLocationPicker from "./WebchatLocationPicker";

import {
  ActiveWidgetCircleButton,
  InActiveCircleButton,
} from "./Buttons/WidgetCircleButton";
import {
  ActiveWidgetCtaButton,
  InActiveCtaButton,
} from "./Buttons/WidgetCtaButton";
import EmailListControl from "../../components/Form/EmailListControl";

const ButtonTypeTitle = ({ title, subtitle }) => (
  <div className="d-flex flex-column">
    <span className="fs-14 mb-2">{title}</span>
    <span className="fs-11 text-secondary">{subtitle}</span>
  </div>
);

export const fields = [
  {
    label: "Button Type",
    tier: "standard",
    body: "Choose between button with a call to action or icon only.",
    as: "selector",
    props: {
      id: "button_style",
      name: "button_style",
      values: [
        {
          id: "button_with_cta",
          name: (
            <ButtonTypeTitle
              title="Text Us"
              subtitle="Icon with CTA, ideal for engagement"
            />
          ),
          activeImage: <ActiveWidgetCtaButton />,
          inactiveImage: <InActiveCtaButton />,
        },
        {
          id: "rounded",
          name: (
            <ButtonTypeTitle
              title="Icon"
              subtitle="Compact button for a minimalist look"
            />
          ),
          activeImage: <ActiveWidgetCircleButton />,
          inactiveImage: <InActiveCircleButton />,
        },
      ],
    },
  },
  {
    id: "theme_start_color",
    label: "Accent Color",
    tier: "standard",
    body: "Choose your brand color for a cohesive look.",
    component: (data, props) => <WebchatColorPicker {...props} />,
  },
  {
    label: "Text color",
    body: "Change the colors for header message and send button.",
    as: "buttonSelector",
    tier: "standard",
    props: {
      id: "text_color",
      name: "text_color",
      values: [
        {
          id: "#FFFFFF",
          name: "White",
        },
        {
          id: "#000000",
          name: "Black",
        },
      ],
    },
  },
  {
    label: "Button Placement",
    body: "Choose the widget placement that suits your website.",
    as: "selector",
    props: {
      id: "button_placement",
      name: "button_placement",
      values: [
        {
          id: "bottom_left_corner",
          name: "Bottom Left Corner",
          activeImage: <img src="/widget/placement-left-selected.svg" />,
          inactiveImage: <img src="/widget/placement-left-unselected.svg" />,
        },
        {
          id: "bottom_right_corner",
          name: "Bottom Right Corner",
          activeImage: <img src="/widget/placement-right-selected.svg" />,
          inactiveImage: <img src="/widget/placement-right-unselected.svg" />,
        },
      ],
    },
  },
  {
    label: "Bottom Offset",
    body: "Adjust widget placement to avoid overlapping with other elements.",
    props: {
      id: "bottom_offset",
      name: "bottom_offset",
      type: "number",
      label: "Bottom offset",
      suffix: "pixels",
    },
  },
  {
    label: "Open on load",
    body: "Enable for immediate visibility of webchat widget on your website.",
    as: "switch",
    props: {
      id: "open_on_load",
      name: "open_on_load",
    },
  },
  {
    label: "Google Reviews",
    body: "Show Google reviews in chat to convert leads with trust.",
    as: "switch",
    tier: "premium",
    props: {
      id: "google_reviews_enabled",
      name: "google_reviews_enabled",
    },
  },
  {
    label: "Google Analytics",
    body: "Activate to track and analyze your webchat performance.",
    as: "switch",
    tier: "premium",
    props: {
      id: "google_analytics_enabled",
      name: "google_analytics_enabled",
    },
  },
  {
    label: "Show Greeter Message",
    body: "Show the greeter messager when the user visits your page.",
    tier: "standard",
    as: "switch",
    props: {
      id: "show_welcome_cta",
      name: "show_welcome_cta",
    },
  },
  {
    id: "greeter_image_url",
    label: "Greeter",
    tier: "premium",
    body: "Upload a custom image to personalize your webchat greeter.",
    as: "selector",
    component: (data, props) => {
      return <WebchatGreeterSelector {...props} />;
    },
  },
  {
    label: "Greeter Message",
    body: "Customize the greeting your users will see when the widget is minimized.",
    tier: "premium",
    props: {
      id: "welcome_cta_text",
      name: "welcome_cta_text",
      maxLength: 50,
    },
  },
  {
    label: "Selected Locations",
    body: "Select the locations that website leads can text.",
    component: (data) => {
      return <WebchatLocationPicker locations={data?.locations} />;
    },
    props: {
      maxWidth: "200px",
    },
  },
  {
    label: "Automated Message",
    body: "Set up an automated reply to instantly respond to new inquiries.",
    as: "textarea",
    props: {
      id: "auto_reply_message",
      name: "auto_reply_message",
      label: "Auto reply message",
    },
  },
  {
    label: "Email Notifications",
    body: "Receive an email when you get a new webchat message.",
    component: <EmailListControl />,
  },
];
