import React from "react";
import Button, { ButtonVarint } from "../../components/Button";
import { delete_ } from "../../lib/api";
import APIResponseError from "../../components/APIResponseError";

const GoogleDisconnect = () => {
  const [isLoading, setIsLoading] = React.useState();
  const [error, setError] = React.useState();

  const onClick = () => {
    setIsLoading(true);

    try {
      delete_("/account/{account_id}/google/oauth?throw=true");
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  return (
    <div className="card-body p-4">
      <h4 className="fw-bold">Disconnect</h4>
      <p className="lead">Disconnect your Google account.</p>
      <Button
        showLoader={isLoading}
        onClick={onClick}
        variant={ButtonVarint.secondaryOutline}
      >
        Disconnect
      </Button>
      <APIResponseError response={error} />
    </div>
  );
};

export default GoogleDisconnect;
