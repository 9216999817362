import React, { useEffect } from "react";
import Modal from "../components/Modal";
import NotificationSubscribe from "./NotificationSubscribe";
import NotificationSubscribeSuccess from "./NotificationSubscribeSuccess";
import NotificationDeniedWarning from "./NotificationDeniedWarning";
import AudibleNotification from "../Messenger/AudibleNotification";
import { useActions, useState } from "../lib/overmind";
import { inIframe } from "../lib/environment";
import NotificationDoneButton from "./NotificationDoneButton";
import NotificationInIframeWarning from "./NotificationInIframeWarning";

export const NotificationPreferenceModalId = "notification-preference-modal";

const NotificationPreferenceModal = () => {
  const { isSubscribed, isDenied } = useState().pushNotification;
  const actions = useActions().pushNotification;

  useEffect(() => {
    actions.refreshStatus();
  }, []);

  return (
    <Modal
      title="Manage notification preferences"
      id={NotificationPreferenceModalId}
    >
      <div className="mb-2">
        <b>Push notifications</b>
        <div hidden={inIframe()}>
          <NotificationSubscribeSuccess hidden={!isSubscribed} />
          <NotificationSubscribe
            hidden={isSubscribed || isDenied}
            onComplete={actions.refreshStatus}
          />
          <NotificationDeniedWarning hidden={!isDenied} />
        </div>
        <NotificationInIframeWarning hidden={!inIframe()} />
      </div>
      <AudibleNotification />
      <NotificationDoneButton />
    </Modal>
  );
};

export default NotificationPreferenceModal;
