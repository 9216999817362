export const settingsV2Adaptor = (fields, data) => {
  const indexedFields = {};
  for (const field of fields) {
    const fieldId = field.id || field.props?.id;
    if (!fieldId) continue;
    indexedFields[fieldId] = field;
  }

  const formValues = {};
  for (const key of Object.keys(data)) {
    const feature = data[key];
    formValues[key] = feature.value;

    if (!feature.can_edit) {
      if (indexedFields[key] === undefined) continue;
      if (!indexedFields[key].props) {
        indexedFields[key].props = {};
      }
      indexedFields[key].props.disabled = true;
      indexedFields[key].tier = feature.tier;
    }
  }

  return [Object.values(fields), formValues];
};
