import React from "react";
import { FeatureTierBadge } from "../FeatureTierBadge";

export const SettingsDescription = ({ title, children, tier }) => {
  return (
    <div style={{ maxWidth: "30ch" }}>
      <div className="d-flex mb-2">
        <b className="me-auto">{title}</b>
        <FeatureTierBadge
          premium={tier === "premium"}
          hidden={tier !== "premium" && tier !== "standard"}
        />
      </div>
      <p className="fs-14">{children}</p>
    </div>
  );
};

const SettingsRow = ({ children, title, body, hidden, tier }) => {
  if (hidden) return null;
  if (title || body) {
    return (
      <div className="d-flex  flex-row">
        <div style={{ width: "50%" }}>
          <SettingsDescription title={title} tier={tier}>
            {body}
          </SettingsDescription>
        </div>
        <div style={{ width: "50%" }}>{children}</div>
      </div>
    );
  }

  return (
    <div className="d-flex  flex-row">
      <div>{children[0]}</div>
      <div>{children[1]}</div>
    </div>
  );
};

export default SettingsRow;
