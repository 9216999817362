import { useFormikContext } from "formik";
import React from "react";
import SelectorButton from "./SelectorButton";

const ButtonSelectorControl = ({ name, values, disabled }) => {
  const [selectedValue, setSelectedValue] = React.useState();
  const formik = useFormikContext();

  React.useEffect(() => {
    setSelectedValue(formik.values[name]);
  }, []);

  const onItemSelect = (newValue) => {
    setSelectedValue(newValue);
    formik.setFieldValue(name, newValue);
  };

  return (
    <div className="d-flex gap-4">
      {values?.map((value) => {
        return (
          <SelectorButton
            disabled={disabled}
            {...value}
            isActive={selectedValue === value.id}
            onSelect={onItemSelect}
          />
        );
      })}
    </div>
  );
};

export default ButtonSelectorControl;
