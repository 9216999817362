import React from "react";
import LabeledField from "../Form/LabeledField";
import SettingsContainer from "./SettingsContainer";
import SettingsRow from "./SettingsRow";
import MergeFieldsTextArea from "../Form/MergeFieldsTextArea";
import Checkbox from "../Form/Checkbox";
import TimezoneSelect from "../TimezoneSelect";
import AddressControl from "../Form/AddressControl";
import Select from "../Form/Select";
import ColorPickerControl from "../Form/ColorPickerControl";
import SelectorControl from "../Form/SelectorControl";
import SwitchControl from "../Form/SwitchControl";
import ButtonSelectorControl from "../Form/ButtonSelectorControl";

const FieldFactory = ({ children, data }) => {
  if (children.as === "textarea") {
    return <MergeFieldsTextArea {...children.props} />;
  }
  if (children.as === "checkbox") {
    return <Checkbox {...children.props} />;
  }
  if (children.as === "timezone") {
    return <TimezoneSelect />;
  }

  if (children.component) {
    if (typeof children.component === "function") {
      return children.component(data, children.props);
    }
    return children.component;
  }

  if (children.as === "address") {
    return <AddressControl />;
  }

  if (children.as === "select") {
    return <Select {...children.props} />;
  }

  if (children.as === "color") {
    return <ColorPickerControl {...children.props} />;
  }

  if (children.as === "selector") {
    return <SelectorControl {...children.props} />;
  }

  if (children.as === "buttonSelector") {
    return <ButtonSelectorControl {...children.props} />;
  }

  if (children.as === "switch") {
    return <SwitchControl {...children.props} />;
  }

  return (
    <LabeledField
      label={children.label}
      className="form-control form-control-sm "
      {...children.props}
    />
  );
};

export const SettingsFactory = ({ fields, data }) => {
  return (
    <SettingsContainer>
      {fields.map((field) => {
        return (
          <SettingsRow
            hidden={field.hidden}
            key={field.label}
            title={field.label}
            body={field.body}
            tier={field.tier}
          >
            <FieldFactory data={data}>{field}</FieldFactory>
          </SettingsRow>
        );
      })}
    </SettingsContainer>
  );
};
