import React, { useEffect, useState } from "react";
import { Field } from "formik";
import SimpleForm from "../../components/Form/SimpleForm";
import LabeledField from "../../components/Form/LabeledField";
import MergeFieldsTextArea from "../../components/Form/MergeFieldsTextArea";
import { CommonMergeFields } from "../../components/Form/MergeFields";
import { post, get } from "../../lib/api";
import PosVisibility from "../../components/PosVisibility";
import ReviewsTestMessageModal from "./ReviewsTestMessageModal";
import LabeledFieldGroup from "../../components/Form/LabeledFieldGroup";
import SettingsRow, {
  SettingsDescription,
} from "../../components/Settings/SettingsRow";
import DefaultReviewSettingsManager from "./DefaultReviewSettingsManager";
import { useActions } from "../../lib/overmind";
import MinDelaySelect from "../../components/Form/MinDelaySelect";
import PosSalesWorkorderCheckbox from "../../components/Form/PosSalesWorkorderCheckbox";
import { containsUrl } from "../../lib/url";
import ReviewLinkDescription from "./ReviewLinkDescription";
import { getShopExternalId } from "../../lib/auth";
import SectionHeader from "../../components/SectionHeader";
import { FeatureTierBadge } from "../../components/FeatureTierBadge";

const mergeFields = [
  CommonMergeFields.firstName,
  CommonMergeFields.lastName,
  CommonMergeFields.shopName,
];

const ReviewsSettings = ({ shopId = getShopExternalId() }) => {
  const [bodyContainsUrl, setBodyContainsUrl] = React.useState();
  const [messageBody, setMessageBody] = useState("");
  const actions = useActions().account;

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setBodyContainsUrl(containsUrl(messageBody));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [messageBody]);

  const apiSubmitHandler = async (data) => {
    if (data.review_link && !data.review_link.includes("https")) {
      data.review_link = `https://${data.review_link}`;
    }
    await post(`/shop/${shopId}/reviews/settings`, data);
    actions.updateReviewTemplate(`${data.body} {review link}`);
  };

  const apiGetHandler = async () => {
    const response = await get(`/shop/${shopId}/reviews/settings`);
    let payload = {
      data: {
        body: messageBody,
        review_link: "",
      },
    };
    if (response.data?.data) {
      payload = response.data;
    }
    setMessageBody(payload.data?.body);
    return payload;
  };

  return (
    <div className="w-100">
      <SectionHeader className="mb-3">
        Reviews Settings <FeatureTierBadge className="ms-3" premium />
      </SectionHeader>
      <SimpleForm
        apiSubmitHandler={apiSubmitHandler}
        apiGetHandler={apiGetHandler}
      >
        <DefaultReviewSettingsManager />
        <Field name="id" type="hidden" />
        <Field name="shop_id" type="hidden" />
        <div className="d-flex flex-column gap-3">
          <SettingsRow>
            <SettingsDescription title="Message" />
            <div>
              <MergeFieldsTextArea
                label="Message Body"
                id="body"
                name="body"
                as="textarea"
                required
                rows={4}
                maxLength={1000}
                mergeFields={mergeFields}
                onChange={(e) => setMessageBody(e.target.value)}
              />
              <div
                hidden={!bodyContainsUrl}
                className="alert alert-warning py-1 mt-2"
              >
                We recommend not putting a link in the message body. Instead put
                your link in the Review Link field below and it will
                automatically be appended.
              </div>
            </div>
          </SettingsRow>
          <SettingsRow>
            <SettingsDescription title="Review Link">
              <ReviewLinkDescription />
            </SettingsDescription>
            <LabeledField
              label="Review Link"
              name="review_link"
              className="form-control form-control-sm w-100"
            />
          </SettingsRow>
          <PosVisibility showAllButStandalone>
            <SettingsRow>
              <SettingsDescription title="Minimum Sale Amount">
                (Optional) Sent a minimum purchase amount.
              </SettingsDescription>
              <LabeledFieldGroup
                inputGroupText="$"
                inputGroupJustify="start"
                label="Minimum Sale Amount"
                name="min_sale_amount"
                className="form-control form-control-sm"
                type="number"
                step=".01"
                minimum="0"
                style={{ maxLength: "100px" }}
              />
            </SettingsRow>
            <SettingsRow>
              <SettingsDescription title="Delay">
                Choose to schedule when the message is sent after the
                transaction date.
              </SettingsDescription>
              <MinDelaySelect />
            </SettingsRow>
            <SettingsRow>
              <SettingsDescription title="Optout Time Period">
                How many days should occur before a customer receives another
                message.
              </SettingsDescription>
              <LabeledFieldGroup
                inputGroupText="days"
                label="Optout Time Period"
                name="optout_time_period"
                className="form-control form-control-sm"
                type="number"
                step="1"
                minimum="0"
                max="730"
                style={{ maxLength: "100px" }}
              />
            </SettingsRow>
            <SettingsRow>
              <SettingsDescription title="Enabled for">
                Choose the type of transaction to send the message to.
              </SettingsDescription>
              <PosSalesWorkorderCheckbox warningMessage="Enabling Reviews will disable NPS if you have it enabled." />
            </SettingsRow>
          </PosVisibility>
        </div>
      </SimpleForm>
      <ReviewsTestMessageModal />
    </div>
  );
};

export default ReviewsSettings;
