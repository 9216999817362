/* eslint-disable react/no-unknown-property */
import { useFormikContext } from "formik";
import React from "react";

const WidgetCircleButton = ({ startColor, endColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99"
      height="99"
      viewBox="0 0 99 99"
    >
      <defs>
        <linearGradient
          id="linear-gradient2"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color={startColor} />
          <stop offset="1" stop-color={endColor} />
        </linearGradient>
        <filter
          id="Rectangle_764"
          x="0"
          y="0"
          width="99"
          height="99"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="6" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="6" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Icon_-_color"
        data-name="Icon - color"
        transform="translate(-673 -392)"
      >
        <g id="Group_1735" data-name="Group 1735">
          <g
            transform="matrix(1, 0, 0, 1, 673, 392)"
            filter="url(#Rectangle_764)"
          >
            <rect
              id="Rectangle_764-2"
              data-name="Rectangle 764"
              width="63"
              height="63"
              rx="31.5"
              transform="translate(18 12)"
              fill="url(#linear-gradient2)"
            />
          </g>
          <g id="chat-left-text" transform="translate(706.8 420.501)">
            <path
              id="Path_28"
              data-name="Path 28"
              d="M27.746,1.982a1.982,1.982,0,0,1,1.982,1.982V19.818A1.982,1.982,0,0,1,27.746,21.8h-19a3.963,3.963,0,0,0-2.8,1.161L1.982,26.925V3.964A1.982,1.982,0,0,1,3.964,1.982ZM3.964,0A3.964,3.964,0,0,0,0,3.964V29.317a.991.991,0,0,0,1.692.7l5.654-5.654a1.982,1.982,0,0,1,1.4-.581h19a3.964,3.964,0,0,0,3.964-3.964V3.964A3.964,3.964,0,0,0,27.746,0Z"
              fill="#fff"
            />
            <path
              id="Path_29"
              data-name="Path 29"
              d="M3,3.991A.991.991,0,0,1,3.991,3H21.827a.991.991,0,0,1,0,1.982H3.991A.991.991,0,0,1,3,3.991ZM3,8.946a.991.991,0,0,1,.991-.991H21.827a.991.991,0,0,1,0,1.982H3.991A.991.991,0,0,1,3,8.946ZM3,13.9a.991.991,0,0,1,.991-.991H13.9a.991.991,0,0,1,0,1.982H3.991A.991.991,0,0,1,3,13.9Z"
              transform="translate(2.946 2.946)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WidgetCircleButton;

export const ActiveWidgetCircleButton = () => {
  const formik = useFormikContext();

  const startColor = formik.values?.theme_start_color || "#6f2dbd";
  const endColor = formik.values?.theme_end_color || "#2e6ad9";

  return <WidgetCircleButton startColor={startColor} endColor={endColor} />;
};

export const InActiveCircleButton = () => {
  return <WidgetCircleButton startColor="#ebebeb" endColor="#ebebeb" />;
};
