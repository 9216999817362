import React from "react";
import { useNavigate } from "react-router-dom";
import { ListItem } from "../../FeatureUpgrade/SplitPanelFeatureUpgrade";
import Button, { ButtonVarint } from "../../components/Button";
import GoogleDisconnect from "./GoogleDisconnect";

const GoogleOAuthReconnect = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="card">
        <div className="card-body p-4">
          <h4 className="fw-bold">
            You have successfully connected your Google account
          </h4>
          <p className="lead">
            Take advantage of a full suite of Google Business Profile features.
          </p>
          <div className="d-flex flex-column gap-3">
            <ListItem>
              Automate Google review requests to be sent out after every sale,
              service, or webchat interaction.
            </ListItem>
            <ListItem>
              Get notified and reply to Google reviews right in Ikeono.
            </ListItem>
            <ListItem>
              Time your review requests perfectly to match your business needs.
            </ListItem>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <div className="card">
            <div className="card-body p-4">
              <h4 className="fw-bold">Configure now and get started</h4>
              <p className="fs-18">
                Customize and automate your first review request to sent out
                today! Discover the impact and importance of this feature in
                this blog post.
              </p>
              <Button
                to="/inbox/reviews"
                className="btn align-self-start"
                style={{
                  minWidth: "240px",
                  height: "53px",
                  border: "none",
                  color: "white",
                  background:
                    'url("/blurble-button.svg") no-repeat 0 0 transparent',
                  backgroundSize: "cover",
                  fontWeight: 600,
                  fontSize: "17px",
                }}
                onClick={() => {
                  navigate("/inbox/reviews");
                }}
              >
                <img
                  style={{ height: "23px" }}
                  src="/sparkle-left.svg"
                  className="me-2"
                />
                Go to Reviews
              </Button>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card">
            <GoogleDisconnect />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleOAuthReconnect;
