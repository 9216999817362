import React from "react";
import Checkbox from "../../components/Form/Checkbox";

const WebchatLocationPicker = ({ locations }) => {
  const [selectedLocations, setSelectedLocations] = React.useState();
  const [title, setTitle] = React.useState("");

  React.useEffect(() => {
    if (!locations) return;
    const filteredLocations = locations?.filter(({ selected }) => selected);

    const locationNames = new Set();
    for (const { name } of filteredLocations) {
      locationNames.add(name);
    }

    setSelectedLocations(locationNames);
  }, locations);

  React.useEffect(() => {
    if (!selectedLocations) return;
    let title_ = "";

    for (const locationName of Array.from(selectedLocations)) {
      title_ = title_.concat(`${locationName}, `);
    }

    setTitle(title_);
  }, [selectedLocations]);

  return (
    <div className="nav-item dropdown me-2 d-none d-md-block">
      <button
        className="btn btn-outline-secondary dropdown-toggle w-100 d-flex align-items-center"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {title}
        </span>
        <i className="b-caret ms-auto" />
      </button>
      <div className="dropdown-menu text-small shadow dropdown-menu-end px-3 pt-4 main-menu w-100">
        <div>
          {locations.map(({ id, name }) => {
            return (
              <Checkbox
                id={`shop__${id}`}
                label={name}
                onChange={(e) => {
                  if (e.target.checked) {
                    selectedLocations.add(name);
                    setSelectedLocations(new Set(selectedLocations));
                  } else {
                    selectedLocations.delete(name);
                    setSelectedLocations(new Set(selectedLocations));
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WebchatLocationPicker;
