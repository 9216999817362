import React from "react";

const WebchatDownloadButton = ({ widgetCode }) => {
  const download = () => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(widgetCode)}`
    );
    element.setAttribute("download", "ikeonoChatWidget.js");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <button
      className="btn btn-sm btn-outline-secondary"
      type="button"
      onClick={download}
    >
      <i className="bi-download" style={{ marginRight: "5px" }} />
      Download Code
    </button>
  );
};

export default WebchatDownloadButton;
