import { useFormikContext } from "formik";
import React from "react";

const SwitchControl = ({ id, name, label, ...props }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div className="form-check form-switch">
      <input
        id={id}
        name={name}
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={values[id]}
        onChange={(e) => {
          setFieldValue(id, e.target.checked);
        }}
        style={{
          height: "25px",
          width: "45px",
        }}
        {...props}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default SwitchControl;
