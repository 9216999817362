import { Field, useFormikContext } from "formik";
import React from "react";

const EmailInput = ({ hidden, onAddEmail }) => {
  const [value, setValue] = React.useState("");
  if (hidden) return null;
  return (
    <div className="input-group mb-3 input-group-sm mt-2">
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        className="form-control "
        placeholder="Recipient's username"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        disabled={!value}
        onClick={() => {
          onAddEmail(value);
          setValue("");
        }}
      >
        Add Email
      </button>
    </div>
  );
};

const EmailRow = ({ children, onRemoveEmail }) => {
  return (
    <tr>
      <td>{children}</td>
      <td className="text-end">
        <button
          className="btn btn-link fs-13 p-0"
          type="button"
          onClick={() => {
            onRemoveEmail(children);
          }}
        >
          <i className="bi-x" />
          Remove
        </button>
      </td>
    </tr>
  );
};

const EmailListControl = () => {
  const { values, setFieldValue } = useFormikContext();
  if (!values) return null;

  const emails = values.alert_emails || [];

  const onRemoveEmail = (email) => {
    const newEmails = emails?.filter((e) => e !== email);
    setFieldValue("alert_emails", newEmails);
  };

  return (
    <div>
      Emails
      <table className="table pt-0 mt-0">
        <tbody>
          {emails?.map((email) => (
            <EmailRow key={email} onRemoveEmail={onRemoveEmail}>
              {email}
            </EmailRow>
          ))}
        </tbody>
      </table>
      <EmailInput
        onAddEmail={(newEmail) => {
          const updatedEmails = values.alert_emails || [];
          updatedEmails.push(newEmail);
          setFieldValue("alert_emails", updatedEmails);
        }}
      />
    </div>
  );
};

export default EmailListControl;
